import axios from 'axios';
import React, {useEffect, useState,} from 'react';
import {useHistory, useParams} from "react-router-dom";

import Footer from '../components/Footer';
import Swal from 'sweetalert2';
import validate from '../config/util';
import PendingTicket from '../components/PendingTicket';
import ServingTicket from '../components/ServingTicket';
import NoShowTicket from '../components/NoShowTicket';
import WaitTicket from '../components/WaitTicket';
import {useNotification} from 'react-hook-notification';
import Header from "../components/Header";
import * as Constants from "../util/Constant";
import {useTranslation} from "react-i18next";

const mqtt = require('mqtt/dist/mqtt');
let client = null;

let CryptoJS = require("crypto-js");
const TokenLiveView = ({ match, location }) => {

    let { id } = useParams();

    const [baseUrl, setBaseUrl] = useState('');
    const [config, setConfig] = useState({});

    const [liveTicketDetails, setLiveTicketDetails] = useState({});
    const [ticketOtherDetails, setTicketOtherDetails] = useState({});
    const [statusController, setStatusController] = useState();
    const [tokenResponse, setTokenResponse] = useState([]);
    const [bookId, setBookId] = useState(0);
    const [fbGiven, setFbGiven] = useState(null);

    const history = useHistory();
    const [branchId, setBranchId] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [ticketId, setTicketId] = useState('');
    const [tid, setTID] = useState('');

    const notification = useNotification();
    const { t } = useTranslation();

    const [wfs, setWorkFlows] = useState(null);

    // get base url
    useEffect(() => {
        let cipherText = localStorage.getItem(Constants.CONFIG);
        if (cipherText == null) {
            fetch(Constants.CONFIG_PATH).then(response => {
                response.json().then(settings => {
                    localStorage.setItem(Constants.CONFIG, settings.cipher);
                    let bytes = CryptoJS.AES.decrypt(settings.cipher, Constants.ENCRYPT_KEY);
                    let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
                    setConfig(decrypt);
                    setBaseUrl(decrypt.API_URL);
                }).catch(error => {
                    console.log("Couldn't find the configurations file");
                    console.error(error);
                })
            });
        } else {
            let bytes = CryptoJS.AES.decrypt(cipherText, Constants.ENCRYPT_KEY);
            let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
            setConfig(decrypt);
            setBaseUrl(decrypt.API_URL);
        }
    }, [TokenLiveView]);

    // get url params
    useEffect(() => {
        setTicketId(id);
        setBranchId(id.slice(0, 4));
    }, [TokenLiveView]);


    // show alert
    useEffect(() => {
        if (statusController === 0) {
            showNotification(t('token.alert1'));
        } else if (statusController === 1) {
            showNotification(t('token.alert2'));
        } else if (statusController === 2) {
            showNotification(t('token.alert3'));
        } else if (statusController === 3) {
            showNotification(t('token.alert4'));
        }
    }, [statusController]);

    // call getLiveTicketDetails function
    useEffect(() => {
        if (baseUrl.length > 0) {
            if (ticketId.length > 0 && branchId.length > 0) {

                if (localStorage.getItem(Constants.USER_PROFILE) === null || localStorage.getItem(Constants.TOKEN) === null) {
                    axios({
                        method: "POST",
                        url: `${baseUrl}` + Constants.SETTINGS + Constants.VERSION + Constants.GENERATE_TOKEN,
                        headers: Constants.HEADER_GET_TOKEN
                    }).then((result) => {
                        localStorage.setItem(Constants.TOKEN, result.data.data);
                        getLiveTicketDetails(true, true);
                    }).catch((error) => {
                        console.log("data fetching error : " + error);
                        swtAlert('error', error);
                    })
                } else {
                    getLiveTicketDetails(true, true);
                }


            } else {
                console.log('ticket id or branch id empty');
            }
        }
    }, [baseUrl, ticketId]);

    // call - subscribe mqttConnect function
    useEffect(() => {
        if (categoryId > 0 && tid.length > 0) {
            mqttConnect();
        } else {
            console.log('categoryId id empty');
        }
    }, [categoryId, tid]);

    // call - unsubscribe mqttConnect function
    useEffect(() => {
        if (statusController === 4) {
            mqttDisconnect();
        }
    }, [statusController]);


    // show notification - function
    function showNotification(text) {
        notification.success({
            title: 'Notification',
            text: text,
            theme: 'light',
            position: 'top-center',
        })
    }

    // auto refresh - subscribe
    function mqttConnect() {

        const host = config.BROKER;
        const clientId = `ws_${Math.random().toString(16).slice(3)}_ts`;

        let options = {
            // protocol: "wss",
            clean: true,
            connectTimeout: 15000,
            useSSL: true,
            // clientId uniquely identifies client
            // choose any string you wish
            username: config.USERNAME,
            password: config.PASSWORD,
            clientId: clientId,
            reconnectPeriod: 1000,
        };
        try {
            client = mqtt.connect(host, options);
            let topicToken = Constants.TOPIC_TRACK + `${tid}`;
            let topicCategory = Constants.TOPIC_CATEGORY + `${categoryId}`;

            // ws connection
            client.on('connect', () => {


                // subscribe to topics
                client.subscribe([topicToken], () => {
                    console.info(`> ` + topicToken)
                });
                client.subscribe([topicCategory], () => {
                    console.info(`> ` + topicCategory)
                });

            });
            client.on("error", (err) => {
                console.error(`Connection to ${host} failed ${err}`);
            });

            //on message arrived
            client.on('message', (topic, payload) => {
                // console.log('Received Message:', topic, payload.toString());
                if (topic === topicToken) {
                    getLiveTicketDetails(true, false);
                } else if (topic === topicCategory) {
                    getTicketOtherDetails(categoryId);
                }
            });
        } catch (e) {
            console.error(`Connection to ${host} failed ${e}`);
        }

    }

    // auto refresh - unsubscribe
    function mqttDisconnect() {
        if (client != null) {
            client.unsubscribe(Constants.TOPIC_TRACK + `${ticketId}`);
            client.unsubscribe(Constants.TOPIC_CATEGORY + `${categoryId}`);
            client.end();
        }
        console.log('topic unsubscribed');
    }

    // getLiveTicketDetails - function
    function getLiveTicketDetails(progress, callOtherService) {

        // loader
        if (progress) {
            Swal.fire({
                position: 'center',
                text: "Loading",
                allowOutsideClick: false,
                width: "200px"
            });

            Swal.showLoading();
        }


        axios({
            method: 'GET',
            url: `${baseUrl}` + Constants.APPOINTMENT + Constants.TRACK_TOKEN + `/${branchId}/${ticketId}`,
            headers: Constants.HEADER_COMMON,
        }).then((result) => {
            if (progress) {
                Swal.close();
                window.navigator.vibrate([100, 30, 100, 30, 200]);
            }

            if (result.data.data.length === 0) {
                // swtAlert('error', 'invalid link'); 
                history.push({
                    pathname: '/link-expired'
                });

            } else {
                let res = result.data.data;
                setTokenResponse(res);
                let data = res[0];

                setTID(data.ticketId);
                setLiveTicketDetails(data);
                setStatusController(data.statusController);

                let categoryId = data.categoryByCategoryId.id;
                setCategoryId(categoryId);

                // call getTicketOtherDetails function
                if (callOtherService)
                    getTicketOtherDetails(categoryId);

                for (let i = res.length - 1; i >= 0; i--) {
                    if (res[i].feedbackmodal !== null) {
                        setFbGiven(true);

                        history.push({
                            pathname: '/link-expired'
                        });
                    }
                    if (res[i].id !== null) {
                        setBookId(res[i].id);
                        // break;
                    }
                }
            }

        }).catch((error) => {
            console.log(error);
            if (progress) Swal.close();
            const errorMsg = validate(error);
            swtAlert('error', errorMsg);
        })
    }

    // getTicketOtherDetails - function
    function getTicketOtherDetails(serviceId) {
        axios({
            method: 'GET',
            url: `${baseUrl}` + Constants.APPOINTMENT + Constants.VERSION + Constants.GET_LIVE
                + `/${serviceId}/${branchId}/null`,
            headers: {
                'X-AUTH-TOKEN': localStorage.getItem(Constants.TOKEN),
                'Content-Type': 'application/json'
            }
        }).then((result) => {
            setTicketOtherDetails(result.data.data);

        }).catch((error) => {
            console.log("getTicketOtherDetails data fetching error : " + error.response);
            const errorMsg = validate(error)
            swtAlert('error', errorMsg);
        })
    }

    // alert - function
    function swtAlert(icon, text) {
        Swal.fire({
            position: 'center',
            icon: icon,
            text: text,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: "Dismiss",
            width: '400px',
        })
    }


    return (
        <div>
            {/* <!-- Begin page content --> */}
            <main className='flex-shrink-1 main d-flex align-content-between flex-wrap'>

                {/* Header */}
                <Header />

                {liveTicketDetails.statusController === 0 ?

                    <PendingTicket liveTicketDetails={liveTicketDetails}
                        tokenResponse={tokenResponse}
                        ticketOtherDetails={ticketOtherDetails}
                        wfs={wfs}
                        setWorkFlows={setWorkFlows}
                    />

                    :

                    liveTicketDetails.statusController === 1 ?

                        <ServingTicket tokenResponse={tokenResponse}
                            setFbGiven={setFbGiven}
                            fbGiven={fbGiven}
                            bookId={bookId}
                            liveTicketDetails={liveTicketDetails}
                            wfs={wfs}
                            setWorkFlows={setWorkFlows}
                        />


                        :

                        liveTicketDetails.statusController === 2 ?

                            <NoShowTicket liveTicketDetails={liveTicketDetails}
                                tokenResponse={tokenResponse}
                                ticketOtherDetails={ticketOtherDetails}
                                wfs={wfs}
                                setWorkFlows={setWorkFlows}
                            />

                            :

                            liveTicketDetails.statusController === 3 ?

                                <WaitTicket liveTicketDetails={liveTicketDetails}
                                    tokenResponse={tokenResponse}
                                    ticketOtherDetails={ticketOtherDetails}
                                    wfs={wfs}
                                    setWorkFlows={setWorkFlows}
                                />

                                :


                                liveTicketDetails.statusController === 4 ?

                                    <ServingTicket liveTicketDetails={liveTicketDetails}
                                        bookId={bookId}
                                        fbGiven={fbGiven}
                                        setFbGiven={setFbGiven}
                                        tokenResponse={tokenResponse}
                                        ticketOtherDetails={ticketOtherDetails}
                                        wfs={wfs}
                                        setWorkFlows={setWorkFlows}
                                    />

                                    :

                                    <></>


                }

                {/* footer */}
                <Footer liveTicketDetails={liveTicketDetails} />

            </main>

        </div>
    )
};

export default TokenLiveView