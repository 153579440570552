import React, {Component} from 'react';


export const KEY_SETTING = 'local_config';
export const KEY_SESSION = 'session_login';
export const COLOR_PRIMARY = '#2e75fb';

export const MOBILE_PREFIX = '+94';

export const ENCRYPT_KEY = 'Ts@S3MAapP.vYw#2022$eyIi%i';

export const CONFIG_PATH = 'configuration.json';
export const CONFIG_TITLE_PATH = 'title.json';
export const CONFIG = '_configuration';
export const TOKEN = '_token';
export const MOBILE = '_mobile';
export const USER_PROFILE = '_prof';
export const LANG = '_lang';

// API URLs
export const VERSION = '/v2';

export const USER = '/user';
export const BRANCH = '/branch';
export const CATEGORY = '/category';
export const APPOINTMENT = '/appointment';
export const SETTINGS = '/settings';
export const FEEDBACK = '/feedback';
export const NOTIFICATION = '/notification';
export const UPDATE = '/update';
export const ALL = '/all';
export const ADD = '/add';
export const LOGOUT = '/logout';
export const KEY = '/key';
export const SEARCH = '/search';
export const CANCEL = '/cancel';
export const LIST = '/list';
export const LANGUAGE = '/lang';
export const SEARCH_SERVICE_BY_KEY = '/search-services-by-key';
export const GENERATE_TOKEN = '/generate-auth-token';
export const GET_LIVE = '/get-live-common';
export const TRACK_TOKEN = '/track-ticket';
export const NEXT_TICKET_QR = '/get-next-ticket-qr';
export const MOBILE_CHECK = '/web-mobile-check';
export const OLD_RESERVATIONS = '/reservations-old';
export const GET_BRANCH = '/get-branch';
export const MARK_ARRIVED = '/mark-arrival';
export const MARK_ARRIVED_TID = '/mark-arrival-tid';
export const CHANGE_LANG = '/change-language';
export const LOGIN_WITH_MOBILE = '/login-with-mobile';
export const LOGIN_WITH_MOBILE2 = '/login-with-mobile-2'; 
export const USER_BY_ID = '/users-by-id';

// mqtt/ws
export const TOPIC_CATEGORY = 'ab:category:';
export const TOPIC_TRACK = 'ab:track:ticket:';

// JWT
export const HEADER_COMMON = {
    'X-AUTH-TOKEN': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJjcmVhdGVyIjoidmloYW5nYSIsImlzcyI6ImF1dGgwIiwidGltZSI6MTY0NjExODIyOCwidHlwZSI6ImF1dGgtZ2VuIiwiZXhwIjoyMDAyMzg4NjI4LCJ1dWlkIjoiVHdCSU82YkU0OE9acyIsImVtYWlsIjoiaW5mb0B0c3RwbC5jb20ifQ.4A98OTSXzICiauu84T682uiPCUEldpRNez9_WtuByOdT5lGva-lWnOoFAQ2ZB7QerxbHPhwPLiR9lfQ_fcjQkQ',
    'Content-Type': 'application/json'
};
export const HEADER_GET_TOKEN = {
    'X-AUTH-TOKEN': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJUU1RQTCIsImlzcyI6ImF1dGgwIiwidHlwZSI6ImF1dGgtdG9rZW4iLCJleHAiOjI1OTUyMTI4NDEsInV1aWQiOiJjM2QzMmYzNS0yNDU5LTRmYjMtOWM4Ny03NzA4ZWYwZDY3NzMifQ.FGPn1nfMO5Nbt9kKBC6sGR3F4hdtm0297wuC8XV-juWhn1eSxe5A4ut5KK-55o8Z3SiQFIzv-F64XYpZhbKR0g',
    'Content-Type': 'application/json'
};
export const HEADER_GET_TOKEN_URL = {
    'X-AUTH-TOKEN': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJhdXRoMCIsInR5cGUiOiJhdXRoLXVybCIsImV4cCI6MjU5NDg3NzUyOH0.oAaRLCQo-_5J9phQW8vjq2CHgkQiB29cjfSJ_AUPxYfsjYSs-gtKdhVE3ssFn7dcs42-oFzU-s8Sn0c4F4iReQ',
    'Content-Type': 'application/json'
};

export default class Constants extends Component {

}

// OTP try again
export const OTP_TRY_AGAIN_MINUTES = 1;
export const OTP_TRY_AGAIN_SECONDS = 59;
