import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import * as Constants from "../util/Constant";

let CryptoJS = require("crypto-js");

const GetTokenModalPrefContact = ({ selectedPreferredContact, setSelectedPreferredContact, setShowGetTokenModal, validatePrefContact, selectedBranch, prefContactMethods, setPrefContactMethods }) => {

    const [baseUrl, setBaseUrl] = useState('');
    // const [prefContactMethods, setPrefContactMethods] = useState([]);

    const { t } = useTranslation();

    // get base url
    useEffect(() => {
        let cipherText = localStorage.getItem(Constants.CONFIG);
        if (cipherText == null) {
            fetch(Constants.CONFIG_PATH).then(response => {
                response.json().then(settings => {
                    localStorage.setItem(Constants.CONFIG, settings.cipher);
                    let bytes = CryptoJS.AES.decrypt(settings.cipher, Constants.ENCRYPT_KEY);
                    let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
                    setBaseUrl(decrypt.API_URL);
                }).catch(error => {
                    console.log("Couldn't find the configurations file");
                    console.error(error);
                })
            });
        } else {
            let bytes = CryptoJS.AES.decrypt(cipherText, Constants.ENCRYPT_KEY);
            let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
            setBaseUrl(decrypt.API_URL);
        }
    }, [GetTokenModalPrefContact])

    // call getPrefContacts function
    useEffect(() => {
        if (baseUrl.length > 0) {
            getPrefContacts();
        }
    }, [baseUrl])

    // getPrefContacts - function
    function getPrefContacts() {

        if (selectedBranch != null) {

            axios({
                method: 'GET',
                url: `${baseUrl}/branch/pref-contacts/${selectedBranch.id}`,
                headers: {
                    'X-AUTH-TOKEN': localStorage.getItem(Constants.TOKEN),
                    'Content-Type': 'application/json'
                }
            }).then((result) => {
                setPrefContactMethods(result.data.data);
            }).catch((error) => {
                console.log('getPrefContacts error');
                console.log(error);
            })

        } else {
            console.log('getPrefContacts error - selectedBranch in empty');
        }
    }

    return (
        <div>
            {prefContactMethods.length > 0 ? (
                <>

                    {/* title */}
                    <div className="row justify-content-center">
                        <div className="col-12 mb-2 text-left">
                            <h6 className="text-primary font-weight-bold px-3 text-center">{t('home.modal.preferredContact')}</h6>
                        </div>
                    </div>

                    {/* methods */}
                    <div className="row justify-content-center mx-0">

                        {prefContactMethods.map((method, i) => (

                            <div className="col-auto radioGroupBelow px-0 mx-0" key={i}>
                                <label className="small">
                                    <input type="radio" className={`option-input-call ${method.contact} radio`}
                                        name="preferredContact" value={method.contact}
                                        defaultChecked={selectedPreferredContact === method.contact}
                                        onClick={(e) => {
                                            setSelectedPreferredContact(e.target.value);
                                        }}
                                    />
                                    {method.contact}</label>
                            </div>

                        ))}

                    </div>
                </>

            ) : null}

            <div className="col-auto my-3">
                <button type="button" className="mb-3 btn btn-primary text-uppercase px-5"
                    data-dismiss="modal" aria-label="Close"
                    onClick={() => {
                        validatePrefContact();
                    }}
                >{t('home.modal.getToken')}
                </button>
                <div className='ahref' style={{ cursor: 'pointer' }}
                    onClick={() => {
                        setShowGetTokenModal(prev => !prev);
                    }}>{t('home.modal.cancel')}</div>
            </div>

        </div>
    )
}

export default GetTokenModalPrefContact