import axios from 'axios';
import React, {useEffect, useState,} from 'react';
import {useHistory} from 'react-router-dom';

import Swal from 'sweetalert2';
import validate from '../config/util';
import {useTranslation} from "react-i18next";
import * as Constants from "../util/Constant";

let CryptoJS = require("crypto-js");
const CustomerFeedBackModal = ({showCfbModal, setShowCfbModal, liveTicketDetails, bookId, fbGiven, setFbGiven}) => {

    const [baseUrl, setBaseUrl] = useState('');

    const [allFeedback, setAllFeedback] = useState([]);
    const [selectedFeedback, setSelectedFeedback] = useState('');

    const {t} = useTranslation();
    const history = useHistory();

    // get base url
    useEffect(() => {
        let cipherText = localStorage.getItem(Constants.CONFIG);
        let bytes = CryptoJS.AES.decrypt(cipherText, Constants.ENCRYPT_KEY);
        let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
        setBaseUrl(decrypt.API_URL);
    }, [CustomerFeedBackModal]);

    //when show modal
    useEffect(() => {
        if (showCfbModal === true) {
            if (baseUrl.length > 0) {
                getAllFeedback();
            }
        }
    }, [showCfbModal]);

    useEffect(() => {
        if (selectedFeedback.length > 0) {
            submitFeedback();
        }
    }, [selectedFeedback]);


    // get all feedback - function
    function getAllFeedback() {
        axios({
            method: 'GET',
            url: `${baseUrl}` + Constants.FEEDBACK + Constants.VERSION + Constants.ALL,
            headers: {
                'X-AUTH-TOKEN': localStorage.getItem(Constants.TOKEN),
                'Content-Type': 'application/json'
            }
        }).then((result) => {

            setAllFeedback(result.data.data);

        }).catch((error) => {

            console.log(error);
            const errorMsg = validate(error);
            swtAlert('error', errorMsg);
        })
    }

    // feedback submit - function
    function submitFeedback() {
        // validate feedback
        if (selectedFeedback.length > 0) {

            // loader
            Swal.fire({
                position: 'center',
                text: t('feedback.alert1'),
                allowOutsideClick: false,
                width: "200px"
            });
            Swal.showLoading();

            // save feedback
            axios({
                method: 'POST',
                url: `${baseUrl}` + Constants.FEEDBACK + Constants.ADD,
                data: {
                    feedbackmodalId: selectedFeedback,
                    counterId: liveTicketDetails.counterId,
                    servingOrderId: liveTicketDetails.soid,
                    bookId: bookId
                },
                headers: {
                    'X-AUTH-TOKEN': localStorage.getItem(Constants.TOKEN),
                    'Content-Type': 'application/json'
                }
            }).then((result) => {
                Swal.close();

                let responseMsg = result.data.message;

                if (responseMsg === 'success') {
                    setFbGiven(true);
                    closeModal();
                } else {
                    swtAlert('error', responseMsg);
                }


            }).catch((error) => {
                Swal.close();
                console.log(error);
                const errorMsg = validate(error);
                swtAlert('error', errorMsg);
            })

        } else {
            swtAlert('error', t('feedback.alert2'));
        }
    }

    // alert - function
    function swtAlert(icon, text) {
        Swal.fire({
            position: 'center',
            icon: icon,
            text: text,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: t('feedback.alert3'),
            width: '400px',
        })
    }

    // close modal - function
    function closeModal() {
        setShowCfbModal(prev => !prev);
        if (localStorage.getItem(Constants.USER_PROFILE) === null) {
            history.push({
                pathname: '/'
            });
        } else {
            if (localStorage.getItem(Constants.USER_PROFILE) === null) {
                history.push({
                    pathname: '/'
                });
                return;
            }
            history.push({
                pathname: '/get-token'
            });
        }
    }

    return (
        <>
            {showCfbModal ? (

                // <OutsideClickHandler>

                <div className='customer-feedback-modal'>
                    <div className="modal-content text-center rounded-bottom-modal ">
                        <div className="modal-header p-0 m-0 bg-primary rounded-top-modal">

                            <img src="img/rocket-img.png" alt="" className="mw-100"
                                 style={{marginTop: '-48px', zIndex: '2000'}}/>

                        </div>
                        <div className="modal-body">
                            <div className="row px-3 pt-2 justify-content-center">
                                <div className="col-12 align-self-center px-3 mb-2">
                                    <h3 className="pb-0 fw700">{t('feedback.header')}</h3>
                                    <h6 className="600">{t('feedback.description')}</h6>
                                </div>
                            </div>

                            {fbGiven ? <></> :
                                <>
                                    <div className="row justify-content-center">
                                        <div className="col-12 mb-2">
                                            <h5 className="fw700 px-3">{t('feedback.rate')}</h5>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mx-0">

                                        {allFeedback.map((feedback, i) => (
                                            <div key={i}>
                                                {
                                                    i === 0 ?
                                                        <div className="col-auto radioGroupBelow px-2 mx-0">
                                                            <label className="small">
                                                                <input type="radio"
                                                                       className="option-input-call happy radio"
                                                                       id="happy" name="rateourService"
                                                                       value={feedback.id}
                                                                       onClick={(e) => {
                                                                           setSelectedFeedback(e.target.value);
                                                                       }}
                                                                />
                                                                {t('feedback.happy')}</label>
                                                        </div>
                                                        :

                                                        i === 1 ?
                                                            <div className="col-auto radioGroupBelow px-2 mx-0">
                                                                <label className="small">
                                                                    <input type="radio"
                                                                           className="option-input-call sad radio"
                                                                           id="sad" name="rateourService"
                                                                           value={feedback.id}
                                                                           onClick={(e) => {
                                                                               setSelectedFeedback(e.target.value);
                                                                           }}
                                                                    />
                                                                    {t('feedback.sad')}</label>
                                                            </div>
                                                            :

                                                            i === 2 ?

                                                                <div className="col-auto radioGroupBelow px-2 mx-0">
                                                                    <label className="small">
                                                                        <input type="radio"
                                                                               className="option-input-call angry radio"
                                                                               id="angry" name="rateourService"
                                                                               value={feedback.id}
                                                                               onClick={(e) => {
                                                                                   setSelectedFeedback(e.target.value);
                                                                               }}
                                                                        />
                                                                        {t('feedback.angry')}</label>
                                                                </div>

                                                                :

                                                                <></>
                                                }

                                            </div>


                                        ))}

                                    </div>
                                </>
                            }

                            {/* <div className="col-auto my-3"> */}
                                {/* {fbGiven ? <></> :
                                    <div className="mb-3 btn btn-primary text-uppercase px-5"
                                         onClick={() => submitFeedback()}>{t('feedback.submit')}
                                    </div>
                                } */}
                                {/* <p style={{cursor: 'pointer'}} onClick={() => closeModal()}>{t('feedback.cancel')}</p> */}
                            {/* </div> */}

                        </div>
                    </div>
                </div>

                // </OutsideClickHandler>

            ) : null}
        </>
    )
};

export default CustomerFeedBackModal