import React, { useState, } from 'react';
import Swal from 'sweetalert2';
import CancelAppointmentModal from '../modal/CancelAppointmentModal';
import { useTranslation } from "react-i18next";
import MyTokenTop from "./MyTokenTop";
import Workflow from "./Workflow";

const PendingTicket = ({ liveTicketDetails, ticketOtherDetails, getLiveTicketDetails, tokenResponse, wfs, setWorkFlows }) => {

    const [showAppoitmentCancelModal, setShowAppoitmentCancelModal] = useState(false);
    const { t } = useTranslation();

    //open modal
    function openAppointmentCancelModal() {
        if (Object.keys(liveTicketDetails).length === 0) {
            swtAlert('error', t('pending.alert1'))
        } else {
            setShowAppoitmentCancelModal(prev => !prev);
        }
    }

    // alert - function
    function swtAlert(icon, text) {
        Swal.fire({
            position: 'center',
            icon: icon,
            text: text,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: t('pending.alert2'),
            width: '400px',
        })
    }


    return (


        <>
            <div className="main-container position-fixed vh-90 no-scroll">
                <div className="container pt-4 text-center">

                    <h6 className="mb-3 fw600">{t('token.header')}</h6>

                    <div className="row mx-3">
                        <div className="col-12 px-0">
                            <div className="card my-token-top-area slide-up">

                                <MyTokenTop liveTicketDetails={liveTicketDetails} />
                                <div
                                    className="card-body bg-white border-0 rounded-top rounded-bottom vh-50 text-center pb-5">
                                    <div className="container">
                                        <div className="row">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className={wfs !== null ? 'container' : 'no-scroll container'}>
                <div className="col-12 " style={{ top: "12em", zIndex: 999 }}>
                    <div className="card bg-transparent shadow-none border-0 text-center pb-2 fade-on-scroll">
                        <div className="card bg-transparent shadow-none border-0 text-center pb-2 fade-on-scroll">
                            <div className="container ">
                                <div className="row mb-3">
                                    <div className="col-6 align-self-center">
                                        <h6 className="text-secondary text-lable fw600 mb-0">{t('pending.department')}</h6>
                                        <h6>{liveTicketDetails.categoryByCategoryId.name}</h6>
                                    </div>
                                    <div className="col-6 align-self-center ">
                                        <h6 className="text-secondary text-lable fw600 mb-0">{t('pending.contact')}</h6>
                                        <h6>{liveTicketDetails.preferredContact}</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 align-self-center">
                                        <h6 className="text-secondary text-lable fw600 mb-0">{t('pending.token')}</h6>
                                    </div>
                                    <div className="col-6 align-self-center">
                                        <h6 className="text-secondary text-lable fw600 mb-0">{t('pending.estimatedTime')}</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 align-self-center">
                                        <h1 className="text-green fw700">{liveTicketDetails.token}</h1>
                                    </div>
                                    <div className="col-6 align-self-center">
                                        <h5 className="fw500">{ticketOtherDetails.avgServingTime}</h5>
                                    </div>
                                </div>
                                <hr className="mt-0" />
                                <div className="row">
                                    <div className="col-6 align-self-center">
                                        <h6 className="text-secondary text-lable fw600 mb-0">{t('pending.servingNo')}</h6>
                                        {ticketOtherDetails.nowServing === null ? (
                                            <h5 className="text-primary fw700">{t('pending.notAvailable')}</h5>
                                        ) : (
                                            <h5 className="text-primary fw700">{ticketOtherDetails.nowServing}</h5>
                                        )}

                                    </div>
                                    <div className="col-6 align-self-center">
                                        <h6 className="text-secondary text-lable fw600 mb-0">{t('pending.watings')}</h6>
                                        <h5 className="text-primary fw700">
                                            {ticketOtherDetails.waitingTickets > 0 ?
                                                ticketOtherDetails.waitingTickets - 1 : ticketOtherDetails.waitingTickets}</h5>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center align-self-center">
                                        <button className="my-4 btn btn-danger"
                                            // data-toggle="modal" data-target="#alertModal"
                                            onClick={openAppointmentCancelModal}
                                        >{t('pending.cancel')}
                                        </button>
                                        <p className="text-danger fw600 p-4 d-none">{t('pending.recalled')}<br />
                                            {t('pending.wait')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Workflow tokenResponse={tokenResponse} wfs={wfs} setWorkFlows={setWorkFlows} />

                </div>
            </div>

            <div className={showAppoitmentCancelModal ? 'modal-outside' : ''}></div>

            <CancelAppointmentModal showAppoitmentCancelModal={showAppoitmentCancelModal}
                setShowAppoitmentCancelModal={setShowAppoitmentCancelModal}
                ticketId={liveTicketDetails.ticketId} getLiveTicketDetails={getLiveTicketDetails} />

        </>

    )
};

export default PendingTicket;