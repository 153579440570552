import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";

const Workflow = ({tokenResponse, wfs, setWorkFlows}) => {

    // const [wfs, setWorkFlows] = useState(null);
    const {t} = useTranslation();

    useEffect(() => {
        if (tokenResponse === null || tokenResponse === undefined || tokenResponse.length === 0) return;

        let bookingList = tokenResponse;
        let workflow = null;

        // get wf
        for (let i = 0; i < bookingList.length; i++) {
            let token = bookingList[i];
            if (token.workflow != null) {
                workflow = token.workflow;
                break;
            }
        }
        if (workflow === null || workflow.length === 0) {
            return;
        }

        let bookings = [];
        let wfs = workflow.list;
        let wfTemps = workflow.list;
        for (let i = bookingList.length - 1; i >= 0; i--) {
            let booking = bookingList[i];
            if (booking.currentWF !== null) {

                for (let j = 0; j < wfTemps.length; j++) {

                    if (wfTemps[j].id === booking.currentWF.id) {
                        wfs.splice(j, 1);
                        break;
                    }
                }
                wfTemps = wfs;
            }

            bookings.push(booking);
        }

        for (let i = 0; i < wfs.length; i++) {
            bookings.push({
                'categoryByCategoryId': {'name': wfs[i].category}
            });
        }

        setWorkFlows(bookings);

    }, [tokenResponse]);

    return (

        wfs === null || wfs.length === 0 ? <></>
            :

            <div className="card bg-eee border-0 rounded-top rounded-bottom" style={{'display': 'block'}}>
                <div className="card-body px-0  ">
                    <ul className="event-list">

                        {wfs.map((wf, i) => (

                            <li key={i}
                                className={wf.endTime ? 'event-block active' : wf.startTime ? 'event-block active-1' : 'event-block active-not'}>
                                <div
                                    className={!wf.endTime && wf.startTime ? 'event-assign-1 right' : 'event-assign right'}>
                                    <div className="event-content bg-eee">
                                        <div className="row">
                                            <div className="col-12 mb-2">
                                                <p style={{marginTop: '2px'}}
                                                   className="fw600 mb-0">{wf.categoryByCategoryId.name}</p>
                                                {/*{!wf.backOffice ? <></> : <p className="fw400">Processing in back office</p>  }*/}

                                            </div>
                                        </div>
                                        <div className="row">
                                            {wf.startTime ?
                                                <>
                                                    <div className="col-4 pr-0">
                                                        <p className="fw300 mb-0">{t('workflow.counter')}</p>
                                                        <p className="text-monospace fw400">{wf.counter ? wf.counter : ''}</p>
                                                    </div>
                                                    <div className="col-4 px-0">
                                                        <p className="fw300 mb-0">{t('workflow.startTime')}</p>
                                                        <p className="text-monospace fw400">{wf.startTime ? wf.startTime : ''}</p>
                                                    </div>
                                                    <div className="col-4 px-0">
                                                        <p className="fw300 mb-0">{t('workflow.endTime')}</p>
                                                        <p className=" text-monospace  fw400">{wf.endTime ? wf.endTime : '--:--:--'}</p>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </li>

                        ))}

                    </ul>

                </div>
            </div>

    )
};
export default Workflow;

