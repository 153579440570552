import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAeobujGRnMtBqaCGOo5fsFCYHkgsLaLZw",
    authDomain: "appointment-booking-tstp-5acf1.firebaseapp.com",
    projectId: "appointment-booking-tstp-5acf1",
    storageBucket: "appointment-booking-tstp-5acf1.appspot.com",
    messagingSenderId: "854636599557",
    appId: "1:854636599557:web:395dff336fab61cda6bf6c"
};

const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);