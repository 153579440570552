import React, { useEffect, useState } from 'react';
import axios from 'axios';
import GetTokenModal from '../modal/GetTokenModal';
import Swal from 'sweetalert2';
import Footer from '../components/Footer';
import validate from '../config/util';
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import { useHistory, useLocation } from "react-router-dom";
import * as Constants from "../util/Constant";

let CryptoJS = require("crypto-js");
const GetToken = () => {

    const [baseUrl, setBaseUrl] = useState('');
    const [title, setTitle] = useState({});
    const [call, setCall] = useState(0);
    const [branchSearchBar, setBranchSearchBar] = useState('');
    const [serviceSearchBar, setServiceSearchBar] = useState('');

    const [showScreen, setShowScreen] = useState(0); //0 - parent screen, 1 - branch screen, 2 - service screen

    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState({});
    const [searchBranchText, setSearchBranchText] = useState('');
    const [isEmptyBranches, setIsEmptyBranches] = useState(false);

    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState({});
    const [searchServiceText, setSearchServiceText] = useState('');
    const [isEmptyServices, setIsEmptyServices] = useState(0);

    const [showGetTokenModal, setShowGetTokenModal] = useState(false);

    const [oldReservationList, setOldReservationList] = useState([]);
    const {t} = useTranslation();

    const location = useLocation();
    const [ticketUrl, setTicketUrl] = useState('');

    const history = useHistory();

    let prof = localStorage.getItem(Constants.USER_PROFILE);

    // get base url
    useEffect(() => {
        let cipherText = localStorage.getItem(Constants.CONFIG);
        if (cipherText == null) {
            fetch(Constants.CONFIG_PATH).then(response => {
                response.json().then(settings => {
                    localStorage.setItem(Constants.CONFIG, settings.cipher);
                    let bytes = CryptoJS.AES.decrypt(settings.cipher, Constants.ENCRYPT_KEY);
                    let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
                    setBaseUrl(decrypt.API_URL);
                    setBranchSearchBar(decrypt.BRANCH_SEARCH_BAR);
                    setServiceSearchBar(decrypt.SERVICE_SEARCH_BAR);
                }).catch(error => {
                    console.error(error);
                })
            });
        } else {
            let bytes = CryptoJS.AES.decrypt(cipherText, Constants.ENCRYPT_KEY);
            let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
            setBaseUrl(decrypt.API_URL);
            setBranchSearchBar(decrypt.BRANCH_SEARCH_BAR);
            setServiceSearchBar(decrypt.SERVICE_SEARCH_BAR);
        }

        // get title from title.json
        fetch(Constants.CONFIG_TITLE_PATH).then(response => {
            response.json().then(title => {
                setTitle(title);
            }).catch(error => {
                console.error(error);
            })
        });

        // get url params
        const params = new URLSearchParams(location.search);
        let ticketUrls = params.get('c');
        if (ticketUrls != null) {
            // set ticket url to state
            setTicketUrl(ticketUrls);
        }

    }, [GetToken]);

    // check & redirect if have reservation
    useEffect(() => {
        if (baseUrl.length > 0) {
            let userData = JSON.parse(prof);
            if (userData !== null) {
                const headers = {
                    "X-AUTH-TOKEN": `${userData.authToken}`,
                    "Content-Type": "application/json",
                };

                axios({
                    method: 'GET',
                    url: `${baseUrl}/appointment/reservations-upcoming/user/${userData.id}`,
                    headers: headers
                }).then((result) => {
                    let respoData = result.data.data;
                    if (respoData.length > 0) {

                        history.push({
                            pathname: `/track/${respoData[0].ticketId}`
                        });
                    }
                })
            }
        }
    }, [baseUrl]);

    // if from qr
    useEffect(() => {
        if (ticketUrl != null && ticketUrl.length > 0) {
            if (baseUrl.length > 0 && call === 1) {
                getBranchByTicketUrl();
            }

        }
    }, [ticketUrl, baseUrl, call]);

    // if default way
    useEffect(() => {
        if (baseUrl.length > 0 && ticketUrl != null) {
            if (ticketUrl.length !== 0) {

                if (prof === null) {
                    axios({
                        method: "POST",
                        url: `${baseUrl}` + Constants.SETTINGS + Constants.VERSION + Constants.GENERATE_TOKEN,
                        headers: Constants.HEADER_GET_TOKEN
                    }).then((result) => {
                        localStorage.setItem(Constants.TOKEN, result.data.data);
                        setCall(1);
                        getAllBranches();
                    }).catch((error) => {
                        console.log("data fetching error : " + error);
                        swtAlert('error', error);
                    })
                } else {
                    setCall(1);
                    getOldReservations();
                    getAllBranches();
                }
            } else {
                getOldReservations();
                getAllBranches();
            }

        }
    }, [baseUrl, ticketUrl]);

    // call getAllServices function when select branch
    useEffect(() => {
        if (Object.keys(selectedBranch).length > 0) {
            getAllServices();
        }
    }, [selectedBranch]);

    // open viewToken modal, when select service
    useEffect(() => {
        if (Object.keys(selectedService).length > 0) {
            openGetTokenModal();
        }
    }, [selectedService]);

    // when click back 
    useEffect(() => {
        return history.listen(loc => {

            if (history.action === 'POP') {

                    // console.log('CLICKED BACK ' + location.pathname );

                    if (location.state !== null && location.state !== undefined) {
                        let preUrl = location.state.prevUrl;
                        if (preUrl === '/login-otp') {
                            history.push('/get-token');
                        } else if (preUrl === '/register-otp') {
                            history.push('/get-token');
                        }
                    }

                    if (loc.pathname === '/login-otp') {
                        history.push({
                            pathname: `/get-token`
                        });
                    } else if (loc.pathname === '/login') {
                        history.push({
                            pathname: `/get-token`
                        });
                    } else if (loc.pathname === '/register') {
                        history.push({
                            pathname: `/get-token`
                        });
                    } else if (loc.pathname === '/register-otp') {
                        history.push({
                            pathname: `/get-token`
                        });
                    } 

                
            }
        })
    })


    //get all branches - function
    function getAllBranches() {
        if (prof === null) return;

        setIsEmptyBranches(false);

        axios({
            method: "GET",
            url: `${baseUrl}` + Constants.BRANCH + Constants.ALL,
            headers: {
                'X-AUTH-TOKEN': localStorage.getItem(Constants.TOKEN),
                'Content-Type': 'application/json'
            }
        }).then((result) => {
            setBranches(result.data.data);
        }).catch((error) => {
            if (error.response.status === 401 || error.response.status === 403) {
                localStorage.removeItem(Constants.MOBILE);
                localStorage.removeItem(Constants.TOKEN);
                localStorage.removeItem(Constants.USER_PROFILE);
                history.push({
                    pathname: '/'
                });
                window.location.reload();
                return;
            }
            console.log("data fetching error : " + error);
            const errorMsg = validate(error);
            swtAlert('error', errorMsg);
        })
    }

    //get branch using ticket url
    function getBranchByTicketUrl() {

        axios({
            method: 'POST',
            url: `${baseUrl}` + Constants.BRANCH + Constants.VERSION + Constants.GET_BRANCH + Constants.BRANCH,
            headers: Constants.HEADER_GET_TOKEN_URL,
            data: {
                key: ticketUrl.replace(' ', '+')
            }
        }).then((result) => {

            let respo = result.data;

            if (respo.message === 'success') {
                setSelectedBranch(respo.data);
            } else {
                swtAlert('error', respo.message)
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    //search branches click event
    function searchBranchesEvent() {
        if (searchBranchText.length === 0) {
            getAllBranches();
        } else {
            getSearchBranches();
        }
    }

    //get search branches - function
    function getSearchBranches() {
        axios({
            method: "GET",
            url: `${baseUrl}` + Constants.BRANCH + Constants.SEARCH + Constants.KEY + `/${searchBranchText}`,
            headers: {
                'X-AUTH-TOKEN': localStorage.getItem(Constants.TOKEN),
                'Content-Type': 'application/json'
            }
        }).then((result) => {
            if (result.data.data.length === 0) {
                setIsEmptyBranches(true);
            } else {
                setIsEmptyBranches(false);
                setBranches(result.data.data);
            }

        }).catch((error) => {
            console.log("data fetching error : " + error)
            const errorMsg = validate(error)
            swtAlert('error', errorMsg);
        })
    }

    //search branch to onChange
    useEffect(() => {
        if (branches.length > 0) {
            if (searchBranchText.length > 2) {
                getSearchBranches();
            } else {
                getAllBranches();
            }
        }
    }, [searchBranchText]);


    //show service screen - function
    function showSelectServiceSceen() {
        if (Object.keys(selectedBranch).length > 0) {
            setShowScreen(2);
        } else {
            swtAlert('error', t('home.alert1'));
        }
    }

    //search services click event
    function searchServicesEvent() {
        if (searchServiceText.length === 0) {
            getAllServices();
        } else {
            getSearchServices();
        }
    }

    //get all services - function
    function getAllServices() {
        setIsEmptyServices(0);

        axios({
            method: "GET",
            url: `${baseUrl}` + Constants.CATEGORY + Constants.ALL + Constants.BRANCH + `/${selectedBranch.id}`,
            headers: {
                'X-AUTH-TOKEN': localStorage.getItem(Constants.TOKEN),
                'Content-Type': 'application/json'
            }
        }).then((result) => {
            setServices(result.data.data);
        }).catch((error) => {
            console.log("data fetching error : " + error);
            const errorMsg = validate(error);
            swtAlert('error', errorMsg);
        });
    }

    //get search services - function
    function getSearchServices() {

        axios({
            method: 'GET',
            url: `${baseUrl}` + Constants.CATEGORY + Constants.SEARCH_SERVICE_BY_KEY + Constants.BRANCH + `/${selectedBranch.id}` + Constants.KEY + `/${searchServiceText}`,
            headers: {
                'X-AUTH-TOKEN': localStorage.getItem(Constants.TOKEN),
                'Content-Type': 'application/json'
            }
        }).then((result) => {
            if (result.data.data.length === 0) {
                setIsEmptyServices(1);
            } else {
                setIsEmptyServices(0);
                setServices(result.data.data);
            }
        }).catch((error) => {
            console.log("data fetching error : " + error);
            const errorMsg = validate(error);
            swtAlert('error', errorMsg);
        })
    }

    //search service to onChange
    useEffect(() => {
        if (services.length > 0) {
            if (searchServiceText.length > 2) {
                getSearchServices();
            } else {
                getAllServices();
            }
        }
    }, [searchServiceText]);


    // openGetTokenModal - function
    const openGetTokenModal = () => {
        setShowGetTokenModal(prev => !prev);
    };

    // validateInputFields - function
    function validateInputFields() {
        if (Object.keys(selectedBranch).length === 0) {
            swtAlert("error", t('home.alert2'));
        } else if (Object.keys(selectedService).length === 0) {
            swtAlert("error", t('home.alert3'))
        } else {
            openGetTokenModal();
        }
    }

    // alert - function
    function swtAlert(icon, text) {
        Swal.fire({
            position: 'center',
            icon: icon,
            text: text,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: t('home.dismiss'),
            width: '400px',
        })
    }

    // getOldReservations - function
    function getOldReservations() {

        // get user id from local storage
        if (prof == null) return;
        let user = JSON.parse(prof);

        axios({
            method: 'GET',
            url: `${baseUrl}` + Constants.APPOINTMENT + Constants.OLD_RESERVATIONS + Constants.USER + '/' + user.id + '/0/10',
            headers: {
                'X-AUTH-TOKEN': localStorage.getItem(Constants.TOKEN),
                'Content-Type': 'application/json'
            }
        }).then((result) => {

            setOldReservationList(result.data.data);

        }).catch((error) => {
            console.log('getOldReservations data fetch error : ' + error);
            const errorMsg = validate(error)
            swtAlert('error', errorMsg);
        })


    }

    return (
        <div>
            <div className="d-flex flex-column h-100">

                {/* <!-- Begin page content --> */}
                <main className="flex-shrink-1 main has-footer d-flex align-content-between flex-wrap">

                    {/* <!-- Fixed navbar --> */}
                    <Header/>


                    {showScreen === 0 ? (

                        // GET TOKEN SCREEN
                        <div className="main-container vh-90 no-scroll">
                            {/* <!-- page content start --> */}
                            <div className="container mt-4 text-center">
                                <h6 className="mb-3">Welcome to {title.title1}</h6>
                                <div className="row mx-3">
                                    <div className="col-6 pl-0 pr-1">

                                        <div className="card"
                                             onClick={(e) => {
                                                 setShowScreen(1);
                                             }}
                                        >
                                            <div className="card-header border-0 pb-0">
                                                <div className="row">
                                                    <div className="col align-self-center">
                                                        <p className="font-weight-bold text-dark text-left text-uppercase">{t('home.branch')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body py-0">
                                                <div className="container">
                                                    <div className="row mx-0">
                                                        <div className="col-10 pl-0 align-self-center text-left">
                                                            {Object.keys(selectedBranch).length === 0 ? (
                                                                <p>{t('home.select.branch')}</p>
                                                            ) : (
                                                                <p>{selectedBranch.name}</p>
                                                            )}

                                                        </div>
                                                        <div className="col-2 px-0 align-self-center ">
                                                            <div className="row float-right">
                                                                {ticketUrl.length === 0 ? (
                                                                    <button className="btn-circle btn-link"
                                                                            type="button">
                                                                        <i className="material-icons vm">arrow_forward</i>
                                                                    </button>
                                                                ) : (
                                                                    <button className="btn-circle btn-link"
                                                                            type="button" style={{cursor: 'unset'}}>
                                                                    </button>
                                                                )}


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-6 pr-0 pl-1">

                                        <div className="card"
                                             onClick={(e) => {
                                                 showSelectServiceSceen();
                                             }}
                                        >
                                            <div className="card-header border-0 pb-0">
                                                <div className="row">
                                                    <div className="col align-self-center">
                                                        <p className="font-weight-bold text-dark text-left text-uppercase">{t('home.service')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body py-0">
                                                <div className="container">
                                                    <div className="row mx-0">
                                                        <div className="col-10 pl-0 align-self-center text-left">
                                                            {Object.keys(selectedService).length === 0 ? (
                                                                <p>{t('home.select.service')}</p>
                                                            ) : (
                                                                <p>{selectedService.name}</p>
                                                            )}

                                                        </div>
                                                        <div className="col-2 px-0 align-self-center ">
                                                            <div className="row float-right">
                                                                <button className="btn-circle btn-link" type="button">
                                                                    <i className="material-icons vm">arrow_forward</i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-12">
                                        <button type="button" className="my-3 btn btn-primary text-uppercase"
                                                onClick={validateInputFields}
                                        >{t('home.viewToken')}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {oldReservationList.length > 0 ? (

                                <div className="container">
                                    <div className="card-body-scroller">

                                        {oldReservationList.map((reservation, i) => (

                                            <div className="card border-0 mx-3 mt-1 mb-0" key={i}>
                                                <div className="card-body">
                                                    <div className="row align-items-center">

                                                        <div className="col-2 mx-0 px-0 text-center">
                                                            <div className="avatar avatar-30 rounded">
                                                                <div className="background"
                                                                     style={{backgroundImage: 'url(img/token-blueoutline-icon.png)'}}
                                                                >
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-8 mx-0 px-0 align-self-center">
                                                            <h6 className="mb-0">{reservation.categoryByCategoryId.name}</h6>
                                                            <p className="small text-secondary">{reservation.categoryByCategoryId.branchByBranchId.name}<span
                                                                className="px-1">{reservation.issuedDate}</span><span>{reservation.issuedTime}</span>
                                                            </p>
                                                        </div>

                                                        {/* icon */}
                                                        {reservation.status === 1
                                                            ?

                                                            <div className="col-2 px-0 text-center">
                                                                <div className="avatar avatar-40 border-0 text-info">
                                                                    <i className="material-icons"
                                                                       title="serving">sync</i>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div/>
                                                        }

                                                        {reservation.status === 2 ?
                                                            <div className="col-2 px-0 text-center">
                                                                <div className="avatar avatar-40 border-0 text-danger">
                                                                    <i className="material-icons"
                                                                       title="noshow">no_accounts</i>
                                                                </div>
                                                            </div>

                                                            :
                                                            <div/>

                                                        }

                                                        {reservation.status === 3 ?

                                                            <div className="col-2 px-0 text-center">
                                                                <div className="avatar avatar-40 border-0 text-dark">
                                                                    <i className="material-icons"
                                                                       title="waiting">airline_seat_recline_extra</i>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div/>


                                                        }

                                                        {reservation.status === 4 ?
                                                            <div className="col-2 px-0 text-center">
                                                                <div className="avatar avatar-40 border-0 text-success">
                                                                    <i className="material-icons"
                                                                       title="served">check_circle</i>
                                                                </div>
                                                            </div>

                                                            :
                                                            <div/>

                                                        }

                                                        {reservation.status === 99 ?
                                                            <div className="col-2 px-0 text-center">
                                                                <div className="avatar avatar-40 border-0 text-danger">
                                                                    <i className="material-icons"
                                                                       title="cancelled">cancel</i>
                                                                </div>
                                                            </div>

                                                            :
                                                            <div/>

                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                        ))}


                                    </div>
                                </div>

                            ) : (

                                <>
                                    {Object.keys(selectedBranch).length === 0 ? (
                                        <div className="text-center mx-auto w-75">
                                            <div className="row align-content-center vh-40">
                                                <div className="col-12">
                                                    <figure className="avatar avatar-60 m-1">
                                                        <div className="background"
                                                             style={{backgroundImage: ("img/live-token-icon.png")}}>
                                                            <img src="img/live-token-icon.png" alt=" "
                                                                 style={{width: '100%'}}/>
                                                        </div>
                                                    </figure>
                                                </div>
                                                <div className="col-12">

                                                    <h5 className="text-secondary">{t('home.liveToken')}</h5>
                                                    <p className="text-secondary text-wrap">{t('home.emptyToken')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {Object.keys(selectedService).length === 0 ? (
                                                <div className="text-center mx-auto w-75">
                                                    <div className="row align-content-center vh-40">
                                                        <div className="col-12">
                                                            <figure className="avatar avatar-60 m-1">
                                                                <div className="background"
                                                                     style={{backgroundImage: ("img/live-token-icon.png")}}>
                                                                    <img src="img/live-token-icon.png" alt=" "
                                                                         style={{width: '100%'}}/>
                                                                </div>
                                                            </figure>
                                                        </div>
                                                        <div className="col-12">
                                                            <h5 className="text-secondary">Select a service</h5>
                                                            <p className="text-secondary text-wrap">{t('home.emptyToken')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="text-center mx-auto w-75">
                                                    <div className="row align-content-center vh-40">
                                                        <div className="col-12">
                                                            <figure className="avatar avatar-60 m-1">
                                                                <div className="background"
                                                                     style={{backgroundImage: ("img/live-token-icon.png")}}>
                                                                    <img src="img/live-token-icon.png" alt=" "
                                                                         style={{width: '100%'}}/>
                                                                </div>
                                                            </figure>
                                                        </div>
                                                        <div className="col-12">
                                                            <h5 className="text-secondary">View Token</h5>
                                                            <p className="text-secondary text-wrap">{t('home.emptyToken')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>


                            )}


                        </div>

                    ) : showScreen === 1 ?

                        // SELECT BRANCH SCREEN
                        <div className="main-container vh-90 no-scroll">
                            <div className="container">
                                <div className="card slide-up">

                                    <div className="card-header border-0 pb-0">
                                        <div className="row">
                                            <div className="col-2 float-left align-self-center">
                                                <button className="btn btn-sm btn-10 btn-link back-btn" type="button"
                                                        onClick={(e) => {
                                                            setShowScreen(0);
                                                        }}
                                                >
                                                    <i className="material-icons vm">arrow_backward</i>
                                                </button>
                                            </div>
                                            <div className="col-10 pr-5 align-self-center">

                                                {branchSearchBar ? (

                                                    <div className="form-group float-label position-relative pt-1 mt-0">
                                                        <div className="bottom-right">
                                                            <div className="btn btn-sm btn-link btn-20 rounded"
                                                                 type="button">
                                                                <i className="material-icons"
                                                                   onClick={searchBranchesEvent}>
                                                                    search_outlined</i>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={"form-group float-label " + (searchBranchText.length > 0 ? 'active' : '')}>
                                                            <input type="text" className="form-control"
                                                                   onKeyUp={(e) => {
                                                                       setSearchBranchText(e.target.value);
                                                                   }}
                                                            />
                                                            <label
                                                                className="form-control-label">{t('home.searchBranch')}</label>
                                                        </div>
                                                    </div>

                                                ) : (
                                                    <div style={{marginBottom: '55px'}}></div>
                                                )}


                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 align-self-center mt-4">
                                                <h6 className="font-weight-bold text-dark text-left">{t('home.selectBranch')}</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <div className="container card-body-scroller">

                                            {isEmptyBranches ? (
                                                <div className='empty-result'>{t('home.empty')}</div>
                                            ) : (
                                                <div>
                                                    {branches.map((branch, i) => (
                                                        <div className="row m-0 py-1 align-item-center" key={i}>
                                                            <div className="col-10">
                                                                <label>{branch.name}</label>
                                                            </div>
                                                            <div className="col-2">
                                                                <input type="radio" className="option-input radio"
                                                                       id="branchID" name="radioName" value={i}
                                                                       defaultChecked={selectedBranch.id === branch.id}
                                                                       onClick={(e) => {
                                                                           setSelectedBranch(branches[e.target.value]);
                                                                           setShowScreen(0);
                                                                       }}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                        </div>


                                    </div>

                                </div>
                            </div>

                        </div>

                        :

                        // SELECT SERVICE SCREEN
                        <div className="main-container vh-90 no-scroll">

                            <div className="container">
                                <div className="card slide-up">

                                    <div className="card-header border-0 pb-0">
                                        <div className="row">
                                            <div className="col-2 float-left align-self-center">
                                                <button className="btn btn-sm btn-10 btn-link back-btn" type="button"
                                                        onClick={(e) => {
                                                            setShowScreen(0);
                                                        }}
                                                >
                                                    <i className="material-icons vm">arrow_backward</i>
                                                </button>
                                            </div>
                                            <div className="col-10 pr-5 align-self-center">

                                                {serviceSearchBar ? (

                                                    <div className="form-group float-label position-relative pt-1 mt-0">
                                                        <div className="bottom-right">
                                                            <div className="btn btn-sm btn-link btn-20 rounded"
                                                                 type="button">
                                                                <i className="material-icons"
                                                                   onClick={searchServicesEvent}
                                                                >search_outlined</i>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={"form-group float-label " + (searchServiceText.length > 0 ? 'active' : '')}>
                                                            <input type="text" className="form-control"
                                                                   onKeyUp={(e) => {
                                                                       setSearchServiceText(e.target.value)
                                                                   }}
                                                            />
                                                            <label
                                                                className="form-control-label">{t('home.searchService')}</label>
                                                        </div>
                                                    </div>

                                                ) : (
                                                    <div style={{marginBottom: '55px'}}></div>
                                                )}


                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 align-self-center mt-4">
                                                <h6 className="font-weight-bold text-dark text-left">{t('home.selectService')}</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <div className="container card-body-scroller">

                                            {isEmptyServices === 1 ? (
                                                <div className='empty-result'>{t('home.empty')}</div>
                                            ) : (
                                                <div>
                                                    {services.map((service, i) => (
                                                        <div className="row m-0 py-1 align-item-center"
                                                             key={service.id}>
                                                            <div className="col-10">
                                                                <label>{service.name}</label>
                                                            </div>
                                                            <div className="col-2">
                                                                <input type="radio" className="option-input radio"
                                                                       id="serviceID" name="radioName" value={i}
                                                                       defaultChecked={selectedService.id === service.id}
                                                                       onClick={(e) => {
                                                                           setSelectedService(services[e.target.value]);
                                                                           setShowScreen(0);
                                                                       }}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    }


                </main>


                {/* <!-- footer--> */}
                <Footer/>

                <div className={showGetTokenModal ? 'modal-outside' : ''}></div>

                {/* get token modal */}
                <GetTokenModal showGetTokenModal={showGetTokenModal} setShowGetTokenModal={setShowGetTokenModal}
                               selectedBranch={selectedBranch} selectedService={selectedService} ticketUrl={ticketUrl}/>


            </div>
        </div>
    )
}

export default GetToken
