import axios from 'axios';
import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";
import * as Constants from "../util/Constant";
import { useHistory } from "react-router-dom";

const CancelAppointmentModal = ({ showAppoitmentCancelModal, setShowAppoitmentCancelModal, ticketId, getLiveTicketDetails }) => {

    let CryptoJS = require("crypto-js");
    const [baseUrl, setBaseUrl] = useState('');

    const history = useHistory();
    const { t } = useTranslation();

    // get base url
    useEffect(() => {
        let cipherText = localStorage.getItem(Constants.CONFIG);
        let bytes = CryptoJS.AES.decrypt(cipherText, Constants.ENCRYPT_KEY);
        let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
        setBaseUrl(decrypt.API_URL);
    }, [CancelAppointmentModal]);

    //cancel appointment - function
    function cancelAppointment() {
        setShowAppoitmentCancelModal(false);

        // loader
        Swal.fire({
            position: 'center',
            text: t('appointment.alert5'),
            allowOutsideClick: false,
            width: "200px"
        });
        Swal.showLoading();

        axios({
            method: 'POST',
            url: `${baseUrl}` + Constants.APPOINTMENT + Constants.CANCEL + `/${ticketId}/0`,
            headers: {
                'X-AUTH-TOKEN': localStorage.getItem(Constants.TOKEN),
                'Content-Type': 'application/json'
            }
        }).then((result) => {
            Swal.close();

            if (result.data.message === 'success') {

                if (localStorage.getItem(Constants.USER_PROFILE) === null) {
                    history.push({
                        pathname: '/'
                    });
                } else {
                    if (localStorage.getItem(Constants.USER_PROFILE) === null) {
                        history.push({
                            pathname: '/'
                        });
                        return;
                    }
                    history.push({
                        pathname: '/get-token'
                    });
                }
            }

        }).catch((error) => {
            Swal.close();
            console.log(error);

            swtAlert('error', t('appointment.alert3'));
        })
    }

    // alert - function
    function swtAlert(icon, text) {
        Swal.fire({
            position: 'center',
            icon: icon,
            text: text,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: t('appointment.alert4'),
            // timer: 2500,
            width: '400px',
        })
    }

    return (
        <>
            {showAppoitmentCancelModal ? (
                <OutsideClickHandler
                    onOutsideClick={() => {
                        setShowAppoitmentCancelModal(prev => !prev);
                    }}
                >
                    <div className='cancel-appointment-modal-root'>
                        <div className="modal-body p-4">
                            <div className="avatar avatar-60 rounded">
                                <figure className="background">
                                    <img src="img/cancel-message-icon.png"
                                        style={{ width: '100%' }}
                                        alt={'cancel'} />
                                </figure>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h6>{t('appointment.header')}</h6>
                                    <p>{t('appointment.description')}</p>
                                </div>

                                <div className="col-12">
                                    <button type="button" className="my-4 btn btn-primary"
                                        onClick={cancelAppointment}
                                    >{t('appointment.cancel')}</button>
                                </div>
                                <div className="col-12">
                                    <p style={{ cursor: 'pointer' }}
                                        onClick={(e) => {
                                            setShowAppoitmentCancelModal(prev => !prev);
                                        }}>{t('appointment.no')}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </OutsideClickHandler>
            ) : null}

        </>

    )
};

export default CancelAppointmentModal