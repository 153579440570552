import React, {useState, useEffect} from 'react';
import QRCode from "qrcode.react";
import axios from 'axios';
import Swal from 'sweetalert2';
import * as Constants from "../util/Constant";
import validate from '../config/util';

let CryptoJS = require("crypto-js");
const QRGenerate = () => {

    const [baseUrl, setBaseUrl] = useState('');
    const [qrBaseUrl, setQrBaseUrl] = useState('');

    const [branchId, setBranchId] = useState('');
    const [password, setPassword] = useState('');

    const [branchUrl, setBranchUrl] = useState('');

    const [qrUrl, setQrUrl] = useState('');


    // get base url
    useEffect(() => {
        let cipherText = localStorage.getItem(Constants.CONFIG);
        if (cipherText == null) {
            fetch(Constants.CONFIG_PATH).then(response => {
                response.json().then(settings => {
                    localStorage.setItem(Constants.CONFIG, settings.cipher);
                    let bytes = CryptoJS.AES.decrypt(settings.cipher, Constants.ENCRYPT_KEY);
                    let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
                    setBaseUrl(decrypt.API_URL);
                    setQrBaseUrl(decrypt.QR_BASE_URL);
                }).catch(error => {
                    console.log("Couldn't find the configurations file");
                    console.error(error);
                })
            });
        } else {
            let bytes = CryptoJS.AES.decrypt(cipherText, Constants.ENCRYPT_KEY);
            let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
            setBaseUrl(decrypt.API_URL);
            setQrBaseUrl(decrypt.QR_BASE_URL);
        }
    }, [QRGenerate])

    // getBranchUrl - function
    function getBranchUrl() {
        if (branchId.length > 0) {

            if (password === 'Ts@0987654321') {
                axios({
                    method: 'POST',
                    url: `${baseUrl}/settings/v2/create-url/branch/${branchId}`,
                    headers: Constants.HEADER_GET_TOKEN_URL
                }).then((result) => {
                    var respo = result.data;
                    if (respo.message === 'success') {
                        setBranchUrl(respo.data);
                        setQrUrl(`${qrBaseUrl}${respo.data}`);
                    } else {
                        console.log('Data fetch failed ', respo.message);
                    }
                }).catch((error) => {
                    console.log('Data fetch error ', error);
                    const errorMsg = validate(error)
                    swtAlert('error', errorMsg);
                })
            } else {
                swtAlert('error', 'Wrong password');
                setBranchUrl('');
            }



        } else {
            swtAlert('error', 'Please enter Branch ID');
            setBranchUrl('');
        }
    }

    // downloadQRCode - function
    const downloadQRCode = () => {
        if (branchUrl.length > 0) {
            // Generate download with use canvas and stream
            const canvas = document.getElementById("qr-gen");
            const pngUrl = canvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = `QR-Code.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } else {
            swtAlert('error', 'No QR to download')
        }
    };

    // alert - function
    function swtAlert(icon, text) {
        Swal.fire({
            position: 'center',
            icon: icon,
            text: text,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: "Dismiss",
            width: '400px',
        })
    }

    return (
        <>
            <div className="App">

                <h4 style={{color: '#fff', margin: '30px 30px 50px'}}>QR Code and Download</h4>

                <form className='qr-generate-form'>
                    <div className="form-group float-label active">
                        <input type="text" className="form-control"
                               onChange={(e) => {
                                   setBranchId(e.target.value);
                               }}/>
                        <label className="form-control-label">Branch Id</label>
                    </div>
                    <div className="form-group float-label active">
                        <input type="password" className="form-control"
                               onChange={(e) => {
                                   setPassword(e.target.value);
                               }}/>
                        <label className="form-control-label">Password</label>
                    </div>

                    <button type="button" className="mb-2 btn btn-primary qr-gen-btn px-5"
                            onClick={getBranchUrl}
                    >Get QR
                    </button>
                </form>

                <br/>

                {branchUrl.length > 0 ? (
                    <QRCode
                        id="qr-gen"
                        value={`${qrBaseUrl}${branchUrl}`}
                        size={350}
                        includeMargin={true}
                        style={{width: '80vw', height: 'auto'}}
                    />
                ) : (
                    <img src="img/no-result.png" style={{width: '50vw', height: 'auto', margin: '25px'}}/>
                )}

                {qrUrl.length > 0 ? (
                    <a href={qrUrl} target='_blank'><h6 style={{color: '#fff', margin: '30px'}}>{qrUrl}</h6></a>
                ) : null}



                <p>

                    <button type="button" className="btn btn-primary qr-gen-btn px-5"
                            onClick={downloadQRCode}
                            style={{marginBottom: '30px', marginTop: '20px'}}
                    >Download QR
                    </button>
                </p>
            </div>
        </>
    )
}

export default QRGenerate